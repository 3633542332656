import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchUsers = createAsyncThunk(
  "usersApi/fetchUsers",
  async ({ page, pageSize }) => {
    const response = await axios.get("/users/allUsers", {
      params: {
        page,
        pageSize,
      },
    });

    return {
      users: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchUserById = createAsyncThunk(
  "usersApi/fetchUserById",
  async (userId) => {
    const response = await axios.get(`/users/allUsers/${userId}`);
    return response.data?.data?.user;
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "usersApi/fetchCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/users/currentUser");
      // console.log("fetchCurrentUser data: ", response.data);
      return response.data;
    } catch (error) {
      console.error("fetchCurrentUser error: ", error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleUser = createAsyncThunk(
  "usersApi/updateRoleUser",
  async (role) => {
    const response = await axios.patch("/users/updateRole", { role });
    return response.data;
  }
);

export const updateJobSeekerProfile = createAsyncThunk(
  "usersApi/updateJobSeekerProfile",
  async (profileData) => {
    const response = await axios.put(
      "/users/updateJobSeekerProfile",
      profileData.jobSeeker
    );
    return response.data;
  }
);

export const updateEmployerProfile = createAsyncThunk(
  "usersApi/updateEmployerProfile",
  async (profileData) => {
    const response = await axios.put(
      "/users/updateEmployerProfile",
      profileData
    );
    return response.data;
  }
);

export const updateServiceProfile = createAsyncThunk(
  "usersApi/updateServiceProfile",
  async (profileData) => {
    const response = await axios.put(
      "/users/updateServiceProfile",
      profileData
    );
    return response.data;
  }
);

export const favoriteUser = createAsyncThunk(
  "usersApi/favoriteUser",
  async (favoriteId) => {
    const response = await axios.post(`/users/favorites/${favoriteId}`);
    return response.data;
  }
);

export const removeFavoriteUser = createAsyncThunk(
  "usersApi/removeFavoriteUser",
  async (favoriteId) => {
    const response = await axios.delete(`/users/removeFromFavorite/${favoriteId}`);
    return response.data;
  }
);

export const fetchFavoriteUsers = createAsyncThunk(
  "usersApi/fetchFavoriteUsers",
  async (filters) => {
    const response = await axios.get("/users/getAllMyFavorites", {
      params: filters,
    });
    return {
      favoriteUser: response.data.data,
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);
