// store/jobs/jobsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchAllJobs, updateJobStatus } from "./jobsThunks"; // Adjust the path according to your structure

const initialState = {
  jobs: [],
  myJobs: [],
  employerPosts: [], // Add a new state for employer's posts
  searchJob: [],
  singleJob: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  employerTotalDocuments: 0,
  employerTotalPages: 1,
  status: "idle",
  searchStatus: "idle",
  applicationStatus: "idle",
  employerPostsStatus: "idle", // Add a new status for employer's posts
  error: null,
  filters: {
    page: 1,
    limit: 10,
    education: "",
    experience: "",
    location: "",
    workingtype: "",
    recommended: false,
    salary: "",
    title: "",
    sort: "",
    recentjob: false,
    numericFilters: "",
    jobType: "",
  },
};

const jobsSlice = createSlice({
  name: "jobsApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetSearchJob(state) {
      state.searchJob = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.jobs;
        state.totalPages = action.payload.totalPages;
        // state.currentPage = action.payload.currentPage;
        // state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchAllJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateJobStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJobStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, status } = action.payload;
        const existingJob = state.jobs.find((job) => job.id === id);
        if (existingJob) {
          existingJob.postingStatus = status;
        }
      })
      .addCase(updateJobStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage, setFilters, resetSearchJob } = jobsSlice.actions;
export default jobsSlice.reducer;
