import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

const ConfirmationModal = ({ onClose, onSubmit }) => {
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleCodeChange = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/\D/g, "").slice(0, 6);
    setCode(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(code);
  };

  return createPortal(
    <div className="fixed z-9999 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </button>
        <h2 className="text-2xl mb-4">Kodni tasdiqlash</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={code}
            onChange={handleCodeChange}
            maxLength="6"
            className="border border-gray-300 rounded p-2 w-full mb-4"
            placeholder="XXXXXX"
            required
          />
          <div className="text-gray-500 mb-4">{seconds} s</div>
          <button
            type="submit"
            className="bg-blue-500 w-full text-white px-4 py-2 rounded"
            disabled={code.length < 6 || seconds === 0}
          >
            Tasdiqlash
          </button>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmationModal;
