/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  deleteTournamentUser,
  fetchTournamentUserId,
} from "../../../store/tournamentApi/tournamentThunk";
import { AppliedBack } from "../../../components/icon";
import DeleteIcon from "../../../assets/img/delete.png";
import toast from "react-hot-toast";

export const AdminTournamentsSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { tournamentUser } = useSelector((state) => state.tournamentApi);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchTournamentUserId(id));
  }, [dispatch, id]);

  const handleDelete = (userId) => {
    dispatch(deleteTournamentUser({ userId, id })).then((res) => {
      if (res.payload.result === "success") {
        toast.success("Foydalanuvchi o'chirildi");
        dispatch(fetchTournamentUserId(id));
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredUsers = tournamentUser?.filter((user) =>
    user.fullName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil((filteredUsers?.length || 0) / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <section className="p-3 sm:p-5 lg:ml-64 lg:mt-16 mt-0">
        <Link
          to={"/admin/tournaments"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Turnirlar
        </Link>
        <div className="mx-auto max-w-screen-2xl mt-5">
          <div className="relative overflow-hidden rounded-md bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="flex gap-3 px-4 py-3 items-center justify-between">
              <div className="flex items-center justify-between">
                <div>
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Filter
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="w-75">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all"
                          type="checkbox"
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor="checkbox-all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Ismi
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Special code
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Telefon raqam
                    </th>
                    <th scope="col" className="px-4 py-3">
                      O'chirish
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length
                    ? currentItems?.map((item) => (
                        <tr
                          key={item.userId}
                          className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                          <td className="w-4 px-4 py-2">
                            <div className="flex items-center">
                              <input
                                id="checkbox-table-search-1"
                                type="checkbox"
                                onClick={(e) => e.stopPropagation()}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="checkbox-table-search-1"
                                className="sr-only"
                              >
                                checkbox
                              </label>
                            </div>
                          </td>
                          <td
                            scope="row"
                            className="px-4 py-2 flex items-center gap-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <img
                              src={item?.avatar}
                              alt="iMac Front Image"
                              className="rounded-full  w-8 h-8"
                            />
                            {item?.fullName}
                          </td>
                          <td className="px-4 py-2">
                            <span className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {item?.specialCode}
                            </span>
                          </td>
                          <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <div className="flex items-center">
                              {item?.phoneNumber}
                            </div>
                          </td>
                          <td className="cursor-pointer px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <img
                              onClick={() => handleDelete(item?.userId)}
                              id={item?.userId}
                              className="w-4 h-4"
                              src={DeleteIcon}
                              alt="name"
                            />
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <nav
              className="flex items-center justify-between p-4"
              aria-label="Table navigation"
            >
              <button
                type="button"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                Oldingi
              </button>
              <span className="text-sm font-semibold text-gray-900 dark:text-gray-400">
                Sahifa
                <span className="font-semibold ms-2 text-gray-900 dark:text-white">
                  {currentPage} of {totalPages}
                </span>
              </span>
              <button
                type="button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                Keyingi
              </button>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
};
