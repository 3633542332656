import React, { useEffect, useState } from "react";
// import { useGetQuickJobsQuery } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllQuickJobs,
  
  updateJobStatus,
} from "../../../store/quickJobs/quickJobsThunks";
import StatusModal from "../../../components/StatusModal";
import toast from "react-hot-toast";

function AdminJobs() {
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const { quickJobs } = useSelector((state) => state.quickJobs);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAllQuickJobs({ page: currentPage, pageSize: 10 }));
  }, [dispatch]);


  const handleStatusUpdate = (status) => {
    if (selectedJob) {
      dispatch(updateJobStatus({ jobId: selectedJob._id, status })).then(res => {
        if(res) {
          dispatch(fetchAllQuickJobs())
          toast.success("Status o'zgardi")
        }
      });
      setSelectedJob(null); 
    }
  };  

  return (
    <div className="lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
        <section className="bg-gray-50 h-screen py-8 antialiased dark:bg-gray-900 md:py-12">
          <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
            <div className="mb-4 items-end justify-between space-y-4 sm:flex sm:space-y-0 md:mb-8">
              <div>
                <h2 className="mt-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                  Barcha ishlar
                </h2>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  id="sortDropdownButton1"
                  data-dropdown-toggle="dropdownSort1"
                  type="button"
                  className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 sm:w-auto"
                >
                  <svg
                    className="-ms-0.5 me-2 h-4 w-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4"
                    />
                  </svg>
                  Sort
                  <svg
                    className="-me-0.5 ms-2 h-4 w-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4">
              {quickJobs?.length
                ? quickJobs?.map((job) => (
                    <div
                      key={job.id}
                      className="rounded-lg border border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800"
                    >
                      <div className="p-4 flex items-center">
                        <img
                          className="w-8 h-8 rounded-full"
                          src={job.hr_avatar}
                          alt="HR Avatar"
                        />
                        <div className="ml-4">
                          <h1 className="text-gray-900 dark:text-white font-semibold text-md">
                            {job.title}
                          </h1>
                        </div>
                      </div>
                      <div className="px-4 py-2">
                        <div className="flex items-center mt-4">
                          <svg
                            className="h-6 w-6 text-gray-700 dark:text-gray-400 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                          </svg>
                          <h1 className="px-2 text-sm text-gray-700 dark:text-gray-400">
                            {job.hr_name}
                          </h1>
                        </div>
                        <div className="flex items-center mt-4">
                          <svg
                            className="h-6 w-6 text-gray-700 dark:text-gray-400 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M17.707 12.293l-1.414 1.414L12 9.414l-4.293 4.293-1.414-1.414L12 6.586l5.707 5.707z" />
                          </svg>
                          <h1 className="px-2 text-sm text-gray-700 dark:text-gray-400">
                            {job.location}
                          </h1>
                        </div>
                        <div className="flex items-center mt-4">
                          <svg
                            className="h-6 w-6 text-gray-700 dark:text-gray-400 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M12 22s-8-4.5-8-11.8A8 8 0 0 1 12 2a8 8 0 0 1 8 8.2c0 7.3-8 11.8-8 11.8z" />
                            <circle cx="12" cy="10" r="3" />
                          </svg>
                          <h1 className="px-2 text-sm text-gray-700 dark:text-gray-400">
                            {new Date(job.createdAt).toLocaleDateString()}
                          </h1>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                          <h1
                            className={`text-lg font-bold text-green-500 dark:text-green-400 `}
                          >
                            {job.postingStatus}
                          </h1>
                          <button
                            onClick={() => setSelectedJob(job)}
                            className="px-3 py-2 bg-gray-800 text-white dark:bg-gray-600 dark:text-gray-200 text-xs font-bold uppercase rounded"
                          >
                            O'zgartirish
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            <div className="w-full text-center">
              <button
                type="button"
                className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
              >
                Show more
              </button>
            </div>
          </div>
        </section>
      </div>
      {selectedJob && (
        <StatusModal
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
          onConfirm={handleStatusUpdate}
        />
      )}
    </div>
  );
}

export default AdminJobs;
