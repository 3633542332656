import React from "react";

export const TournamentModal = ({
  setShowModal,
  handleInputChange,
  handleImageChange,
  handleSubmit,
  formData,
}) => {
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed  inset-0 z-50 top-20 overflow-y-auto flex items-center justify-center"
    >
      <div className="relative    p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative border border-solid border-meta-10 p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Yangi turnir qo'shish
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setShowModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Modalni yopish</span>
            </button>
          </div>

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="tournament_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Turnir nomi
                </label>
                <input
                  type="text"
                  name="tournament_name"
                  id="tournament_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Turnir nomini kiriting"
                  required
                  value={formData.tournament_name}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="prize_pool"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sovrin jamg'armasi
                </label>
                <input
                  type="text"
                  name="prize_pool"
                  id="prize_pool"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Sovrin jamg'armasini kiriting"
                  required
                  value={formData.prize_pool}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="date_range"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sana oralig'i
                </label>
                <input
                  type="text"
                  name="date_range"
                  id="date_range"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Sana oralig'ini kiriting"
                  required
                  value={formData.date_range}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="organizer"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tashkilotchi
                </label>
                <input
                  type="text"
                  name="organizer"
                  id="organizer"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Tashkilotchini kiriting"
                  required
                  value={formData.organizer}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="game"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  O'yin
                </label>
                <input
                  type="text"
                  name="game"
                  id="game"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="O'yinni kiriting"
                  required
                  value={formData.game}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="platform"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Platforma
                </label>
                <input
                  type="text"
                  name="platform"
                  id="platform"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Platformani kiriting"
                  required
                  value={formData.platform}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="location"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Joylashuv
                </label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Joylashuvni kiriting"
                  required
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="type"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Turnir turi
                </label>
                <input
                required
                  type="text"
                  name="type"
                  id="type"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Turnir turini kiriting"
                  value={formData.type}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor="image"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Rasm yuklash
                </label>
                <input
                required
                  type="file"
                  name="image"
                  id="image"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Rasmni yuklang"
                  onChange={handleImageChange}
                />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Turnir ta'rifi
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Turnir haqida qisqacha ta'rif yozing..."
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                className="py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                onClick={() => setShowModal(false)}
              >
                Bekor qilish
              </button>
              <button
                type="submit"
                className="text-dark dark:text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Saqlash
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TournamentModal;
