// hooks/useAuth.js
import { useSelector, useDispatch } from "react-redux";
import { codeVerify, login } from "../store/auth/authThunks";
import { logout, setLoading } from "../store/auth/authSlice";

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const userLogin = (user) => {
    return dispatch(login(user)).unwrap(); // Ensure this returns a promise
  };

  const userCodeVerify = (user) => {
    return dispatch(codeVerify(user)).unwrap();
  };
  const userLogout = (token) => {
    dispatch(logout(token));
  };
  const setAuthLoading = (loading) => {
    dispatch(setLoading(loading));
  };

  return {
    ...auth,
    userLogin,
    userLogout,
    setAuthLoading,
    userCodeVerify,
  };
};

export default useAuth;
