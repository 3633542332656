import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthAdmin from "./useAdminQuery";

const adminApiSlice = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithReauthAdmin,
  tagTypes: [
    "User",
    "JobSeeker",
    "Employer",
    "Job",
    "QuickJob",
    "Company",
    "Office",
  ],
  endpoints: (builder) => ({}),
});

export default adminApiSlice;
