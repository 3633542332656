import React from "react";
import TableSampleOffice from "../../../components/tables/OfficeTable";

export const AdminOffice = () => {
  return (
    <div>
      <div className="lg:ml-64">
        <div className="rounded-lg  dark:border-gray-700 lg:mt-16 mt-0  ">
          <TableSampleOffice />
        </div>
      </div>
    </div>
  );
};
