// Code: Auth slice for handling user login, register, logout, and loading state
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../InitialState";
import Cookies from "js-cookie";
import {
  codeVerify,
  login,
  signOut,
  signOutRole,
} from "./authThunks";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("userData");
      signOut();
      window.location.href = "/";
    },
    logoutRole: (state) => {
      state.user = null;
      localStorage.removeItem("userData");
      Cookies.remove("user", { path: "/" });
      signOutRole();
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      })
    
      .addCase(codeVerify.fulfilled, (state, action) => {
        state.user = action.payload;
      })
    
      .addCase(signOut.fulfilled, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        state.tokenExpiry = null;
        state.user = null;
      })
      .addCase(signOutRole.fulfilled, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        state.tokenExpiry = null;
        state.user = null;
      });
  },
});

export const { logout, setLoading, logoutRole, updateAccessToken } = authSlice.actions;
export const authReducer = authSlice.reducer;
