import adminApiSlice from './adminApi';

// Make sure the adminApiSlice is properly created and configured
// Example for adminApiSlice setup (adminApi.js file):
// const adminApiSlice = createApi({
//   reducerPath: 'adminApi',
//   baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
//   endpoints: (builder) => ({}),
// });

export const userApiSlice = adminApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `getUsersForAdmin`,
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    blockUser: builder.mutation({
      query: (userId) => ({
        url: `blockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    unblockUser: builder.mutation({
      query: (userId) => ({
        url: `unBlockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getJobSeekers: builder.query({
      query: () => 'getJobSeekersForAdmin',
      providesTags: [{ type: 'JobSeeker', id: 'LIST' }],
    }),
    getEmployers: builder.query({
      query: () => 'getEmployersForAdmin',
      providesTags: [{ type: 'Employer', id: 'LIST' }],
    }),
    getJobs: builder.query({
      query: () => 'getJobsForAdmin',
      providesTags: [{ type: 'Job', id: 'LIST' }],
    }),
    getQuickJobs: builder.query({
      query: () => 'getQuickjobsForAdmin',
      providesTags: [{ type: 'QuickJob', id: 'LIST' }],
    }),
    getCompanies: builder.query({
      query: () => 'getOfficesForAdmin',
      providesTags: [{ type: 'Company', id: 'LIST' }],
    }),
    getCompany: builder.query({
      query: ({ page = 1, limit = 10 }) =>
        `getOfficesForAdmin/?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Office", id: "LIST" }],
    }),
    createCompany: builder.mutation({
      query: (newCompany) => ({
        url: '/offices',
        method: 'POST',
        body: newCompany,
      }),
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
    }),
  }),
  overrideExisting: false, // Ensure that existing endpoints are not overridden
});

export const {
  useGetUsersQuery,
  useBlockUserMutation,
  useUnblockUserMutation,
  useGetJobSeekersQuery,
  useGetEmployersQuery,
  useGetJobsQuery,
  useGetQuickJobsQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
} = userApiSlice;
