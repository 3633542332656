// store/openApiThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchTournament = createAsyncThunk(
  "tournamentApis/fetchTournaments",
  async () => {
    const response = await axios.get("/tournaments");
    return response.data?.data;
  }
);

export const fetchTournamentId = createAsyncThunk(
  "tournamentApis/fetchTournamentsId",
  async (id) => {
    const response = await axios.get(`/tournaments/${id}`);
    return response.data?.data;
  }
);

export const fetchTournamentUserId = createAsyncThunk(
  "tournamentApis/fetchTournamentUserId",
  async (id) => {
    const response = await axios.get(`/tournaments/${id}/users`);
    return response.data?.data;
  }
);

export const fetchTournamentJoin = createAsyncThunk(
  "tournamentApis/fetchTournamentsJoin",
  async (id) => {
    const response = await axios.post(`/tournaments/${id}/join`);
    return response.data?.data;
  }
);

export const deleteTournamentUser = createAsyncThunk(
  "tournamentApis/deleteTournamentUser",
  async ({ userId, id }) => {
    const response = await axios.post(`/tournaments/${id}/removeUser`, {
      userId,
    });
    return response.data;
  }
);

export const createTournament = createAsyncThunk(
  "tournamentApis/createTournament",
  async (formData) => {
    const response = await axios.post("/tournaments", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data?.data;
  }
);

export const deleteTournamentById = createAsyncThunk(
  "jobsApi/deleteTournamentById",
  async (id) => {
    await axios.delete(`/tournaments/${id}`);
    return id; // Return the deleted job ID
  }
);

export const updateStatusTournament = createAsyncThunk(
  "quickJobsApi/updateStatusTournament",
  async ({ id, status }) => {
    const response = await axios.patch(
      `/tournaments/${id}/updateStatus`,
      {status}
    );
    return response.data.data; // Adjust based on actual response structure
  }
);
