// store/jobsApi/jobsThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchAllJobs = createAsyncThunk(
  "jobsApi/fetchAllJobs",
  async (filters) => {
    const response = await axios.get("/jobs/forAdmin", {
      params: filters, // Spread the filters object to include all filter parameters
    });
    return {
      jobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      // currentPage: response.data.pagination.currentPage,
      // totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const updateJobStatus = createAsyncThunk(
  "jobs/updateJobStatus",
  async ({ jobId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/jobs/${jobId}/approveOrReject`, {
        postingStatus: status,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
