// store/openApiThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure


export const fetchAllQuickJobs = createAsyncThunk(
  "quickJobsApi/fetchAllQuickJobs",
  async (filters) => {
    const response = await axios.get("/quickjobs/forAdmin", {
      params: {
        page: 1,
        limit: 10,
      }, // Spread the filters object to include all filter parameters
    });
    return {
      quickJobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      // currentPage: response.data.pagination.currentPage,
      // totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);
export const updateJobStatus = createAsyncThunk(
  'quickJobs/updateJobStatus',
  async ({ jobId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/quickJobs/${jobId}/approveOrReject`, { status });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);