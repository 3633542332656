import React from "react";
import TableSampleClients from "../../../components/tables/UserTable";
function AdminUsers() {
  return (
    <div className="lg:ml-64">
      <div className="rounded-lg  dark:border-gray-700 lg:mt-16 mt-0">
        <TableSampleClients />
      </div>
    </div>
  );
}

export default AdminUsers;