// store/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllQuickJobs,
  updateJobStatus,
} from "./quickJobsThunks"; // Strukturangizga mos ravishda yo'lani to'g'rilang

const initialState = {
  jobs: [],
  quickJobs: [],
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  allResult: 1,
  status: "idle",
  error: null,
  message: "",
  totalCount: 0,
};

const quickJobsSlice = createSlice({
  name: "quickJobsSlices",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = action.payload.quickJobs;
        state.totalPages = action.payload.totalPages;
        // state.currentPage = action.payload.currentPage;
        // state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchAllQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
   
      .addCase(updateJobStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJobStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, status } = action.payload;
        const existingJob = state.jobs.find((job) => job.id === id);
        if (existingJob) {
          existingJob.postingStatus = status;
        }
      })
      .addCase(updateJobStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage } = quickJobsSlice.actions;
export default quickJobsSlice.reducer;
