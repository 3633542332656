// store/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  createTournament,
  deleteTournamentById,
  deleteTournamentUser,
  fetchTournament,
  fetchTournamentId,
  fetchTournamentJoin,
  fetchTournamentUserId,
  updateStatusTournament,
} from "./tournamentThunk";

const initialState = {
  tournament: [],
  tournamentSingle: [],
  tournamentUser: [],
  tournamentJoin: [],
  currentPage: 1,
  totalPages: 1,
  status: "idle",
  error: null,
};

const tournamentApiSlice = createSlice({
  name: "tournamentApi",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTournament.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournament = action.payload; // Ensure this matches the API response
      })
      .addCase(fetchTournament.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchTournamentId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTournamentId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournamentSingle = action.payload; // Ensure this matches the API response
      })
      .addCase(fetchTournamentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchTournamentUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTournamentUserId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournamentUser = action.payload; // Ensure this matches the API response
      })
      .addCase(fetchTournamentUserId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTournamentJoin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTournamentJoin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournamentJoin = action.payload; // Ensure this matches the API response
      })
      .addCase(fetchTournamentJoin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteTournamentUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTournamentUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournamentJoin = action.payload; // Ensure this matches the API response
      })
      .addCase(deleteTournamentUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(createTournament.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTournament.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tournament.push(action.payload); // Add the new job to the list
      })
      .addCase(createTournament.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteTournamentById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTournamentById.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (Array.isArray(state.tournament)) {
          // Check if state.jobs is an array
          state.tournament = state.tournament.filter(
            (tournament) => tournament._id !== action.payload
          ); // Remove the deleted job from the list
        }
      })
      .addCase(deleteTournamentById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateStatusTournament.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatusTournament.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.tournament.findIndex(
          (tour) => tour._id === action.payload._id
        );
        if (index !== -1) {
          state.tournament[index] = action.payload; // Update the job in the list
        }
      })
      .addCase(updateStatusTournament.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tournamentApiSlice.reducer;
