/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTournament,
  createTournament,
  deleteTournamentById,
  updateStatusTournament,
} from "../../../store/tournamentApi/tournamentThunk";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import TournamentModal from "../../../components/modals/TournamentModal";

export const AdminTournaments = () => {
  const dispatch = useDispatch();
  const { tournament } = useSelector((state) => state.tournamentApi);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    tournament_name: "",
    prize_pool: "",
    date_range: "",
    organizer: "",
    game: "",
    platform: "",
    location: "",
    type: "",
    image: null,
    description: "",
  });

  useEffect(() => {
    dispatch(fetchTournament());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    dispatch(createTournament(data)).then((res) => {
      if (res) {
        toast.success("Turnir qo'shildi!");
        dispatch(fetchTournament());
      }
    });
    setShowModal(false);
  };

  const handleDelete = (e, id) => {
    e.preventDefault(); 
    dispatch(deleteTournamentById(id)).then((res) => {
      if (res) {
        toast.success("Turnir o'chirildi!");
        dispatch(fetchTournament());
      }
    });
  };

  const handleStatusChange = (e, id, status) => {
    e.preventDefault();
    // if(status === "coming") {
    //   toast.error("Bu status tez kunda qo'shiladi!")
    //   return
    // }
    dispatch(updateStatusTournament({ id, status }))
      .then((res) => {
        console.log(res);
        if (res?.payload) {
          toast.success("Turnir statusi yangilandi!");
          dispatch(fetchTournament());
        }else {
          toast.error("Nimadir xatolik chiqdi!");

        }
      })
  };
  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-white dark:text-gray-800 mb-3 text-2xl font-bold">
            Turnirlar ro'yxati
          </h3>
          <div className="flex justify-center m-5">
            <button
              onClick={() => setShowModal(true)}
              className="block text-white font-medium bg-blue-900 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300  rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="button"
            >
              Turnir qo'shish
            </button>
          </div>
          <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {tournament.map((tourney) => (
              <Link
                to={`/admin/tournaments/${tourney._id}`}
                key={tourney._id}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg cursor-pointer"
              >
                <div className="relative">
                  <img
                    className="w-full h-45 object-cover object-center rounded-t-lg"
                    src={tourney.image}
                    alt={`${tourney.tournament_name} image`}
                  />
                  <p className="bg-red-400 text-white text-center px-3 py-1 absolute top-0 right-0">
                    {tourney?.status === "open" ? "Ochiq" : tourney.status === "closed" ? "Yopiq" : tourney.status === "expired" ? "Muddati tugagan" : tourney.status === "coming" ? "Tez kunda" :""}
                  </p>
                </div>
                <div className="p-4">
                  <h3 className="text-md text-gray-600 dark:text-gray-300 font-semibold mb-2">
                    {tourney.tournament_name}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 text-sm">
                    {tourney.date_range}
                  </p>
                  <p className="text-gray-600 dark:text-gray-300 text-sm">
                    {tourney.location}
                  </p>
                  <div>
                    <div className="mt-5">
                      <button
                        onClick={(e) =>
                          handleStatusChange(e, tourney._id, "open")
                        }
                        id="open"
                        type="button"
                        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
                      >
                        Ochiq
                      </button>
                      <button
                        onClick={(e) =>
                          handleStatusChange(e, tourney._id, "closed")
                        }
                        id="closed"
                        type="button"
                        className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
                      >
                        Yopiq
                      </button>

                      <button
                        onClick={(e) =>
                          handleStatusChange(e, tourney._id, "expired")
                        }
                        id="expired"
                        type="button"
                        className="text-white bg-gradient-to-r from-gray-500 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
                      >
                        Muddati tugagan
                      </button>
                      <button
                        onClick={(e) =>
                          handleStatusChange(e, tourney._id, "coming")
                        }
                        id="coming"
                        type="button"
                        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
                      >
                        Tez kunda
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleDelete(e, tourney._id)}
                    type="button"
                    class="text-white w-full mt-4 bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  >
                    O'chirish
                  </button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <TournamentModal
          setShowModal={setShowModal}
          handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          handleSubmit={handleSubmit}
          formData={formData}
        />
      )}
    </div>
  );
};
